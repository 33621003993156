<template>
    <div><h2>edit</h2></div>
    {{ user }}
<!--    <div v-for="param in user" :key="user.id"></div>-->
</template>

<script>
    export default {
        name: 'edit-user',

        created() {
            console.log('111alert');
        },
        mounted() {
            // console.info('App currentRoute:', this.$router.currentRoute.value.params.id)
            // console.log('userId edit', this.$store.state.userId);
            // console.log('users edit', this.$store.state.users);
            // this.$store.dispatch('fetchUser', this.$router.currentRoute.value.params.id);
        },
        computed: {
            user() {
                console.log('user list');
                console.log(this.$store.state.user);
                return this.$store.state.user;
            }
        },
    }
</script>